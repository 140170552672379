<template>
  <div class="ps-form__footer">
    <router-link :to="{name: 'home'}">Back to home </router-link>
    <!-- <p>Connect with:</p>
    <ul class="ps-list--social">
      <li>
        <a class="facebook" href="#"
          ><i class="fa fa-facebook"></i
        ></a>
      </li>
      <li>
        <a class="google" href="#"
          ><i class="fa fa-google-plus"></i
        ></a>
      </li>
      <li>
        <a class="twitter" href="#"
          ><i class="fa fa-twitter"></i
        ></a>
      </li>
      <li>
        <a class="instagram" href="#"
          ><i class="fa fa-instagram"></i
        ></a>
      </li>
    </ul> -->
  </div>
</template>

<script>
export default {
  name: "AuthFooter",
  props: {
    'section': String
  }
}
</script>

<style scoped>

</style>