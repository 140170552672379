<template>
  <div class="ps-page--my-account">
    <div class="ps-panel__header" style="background-color:#000">
      <div class="row">
        <div class="col-4 left-align">
          <router-link :to="{ name: 'my_profile' }" v-if="auth.isAuthenticated">
            myPahukama
          </router-link>
          <router-link :to="{ name: 'login' }" v-else>myPahukama</router-link>
        </div>
        <div class="col-4">
          <a class="ps-logo" href="/">
            <img
              src="/img/logo.png"
              alt="Pahukama"
              max-width="100%"
              height="auto"
            />
          </a>
        </div>
        <div class="col-4 right-align">
          <router-link :to="{ name: 'merchant_register' }"
            >Merchant</router-link
          >
        </div>
      </div>
    </div>
    <div class="ps-my-account">
      <div class="ps-container">
        <form class="ps-form--account ps-tab-root">
          <ul class="ps-tab-list">
            <li class="active">
              <a href="#sign-in">Reset Password</a>
            </li>
          </ul>
          <div class="ps-tabs">
            <div class="ps-tab active" id="sign-in">
              <div class="ps-form__content">
                <h5>Set the new password</h5>
                <div
                  v-if="errors.message"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ errors.message }}
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    v-model="user.password"
                    type="text"
                    placeholder="Password"
                  />
                  <small
                    v-if="errors.password"
                    class="mobile-number-error text-danger"
                    >{{ errors.password[0] }}</small
                  >
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    v-model="user.password_confirmation"
                    type="text"
                    placeholder="Confirm Password"
                  />
                  <small
                    v-if="errors.confirm_password"
                    class="mobile-number-error text-danger"
                    >{{ errors.password_confirmation[0] }}</small
                  >
                </div>
                <div class="form-group submit">
                  <button
                    class="ps-btn ps-btn--fullwidth login"
                    :disabled="loading"
                    @click.prevent="onSubmit()"
                  >
                    {{ loading ? "" : "Save New Password" }}
                    <i v-if="!loading" class="icon icon-right-arrow ml-1"></i>
                    <div
                      v-if="loading"
                      class="spinner-border text-light"
                      role="status"
                    ></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AuthFooter from "./AuthFooter";
import axios from "axios";

export default {
  name: "ResetPassword",
  components: { AuthFooter },
  data() {
    return {
      user: {
        email: "",
        password_confirmation: "",
        password: "",
        verification_code: "",
      },
      token: '',
      errors: {
        email: [],
        message: "",
      },
      loading: false,
      verify: false,
    };
  },

  mounted: function() {
    this.token = this.$route.query.token;
    this.user.email = this.$route.query.email;
  },

  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  methods: {
    onSubmit() {
      this.loading = true;
      let data = { 
        email: this.user.email,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation,
        token: this.token
      };

      axios
        .post("reset/password", data)
        .then((response) => {
          if (response.data.status == 'passwords.reset') {
            const yourFunction = async () => {
              this.$toasted.show("Password reset successful. Login you in now...");

              //location.href = "/";

              // Login User
              let data = { email: this.user.email, password: this.user.password };
              axios
                .post("login", data)
                .then((response) => {
                  if (response.data.success) {
                    this.$toasted.show("You have successfully been logged in");
                    this.$store.dispatch("auth/updateProfile", response.data.user);
                    this.$store.dispatch("auth/updateToken", response.data.token);
                    let nextPage = "home";
                    if (response.data.user.is_admin) {
                      nextPage = "admin_dashboard";
                    } else if (response.data.user.shop) {
                      nextPage = "merchant_dashboard";
                    }
                    if (this.$store.state.auth.next) {
                      nextPage = this.$store.state.auth.next;
                      this.$store.commit("auth/next", null);
                    }
                    this.$router.push({ name: nextPage });
                  }
                  this.isLoading = false;
                })
                .catch((error) => {
                  //
                });
            };
            yourFunction();
          }
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.message = "Please enter all required fields.";
            this.errors = error.response.data.errors;
          } else if (error.response.status === 401) {
            this.errors.message = error.response.data.message;
          } else {
            this.message = error.backendErrors[0];
          }
          this.isError = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
